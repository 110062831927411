import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/privacy.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/footer.css'
import React from 'react'
import { Header } from '../components/Header'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import Seo from '../components/Seo'
import { url } from '../config/domain'

export default function Privacy({ location }: any): JSX.Element {
  const locatePath = location.pathname.split('/')

  return (
    <div>
      <div className="wrapper">
        <Seo
          title={'プライバシーポリシー'}
          description={
            'KURORO BLOGを運営するためのプライバシーポリシー詳細ページです。KURORO BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。'
          }
          url={url + 'privacy/'}
          noIndex={true}
          contentType="article"
        />
        <Header location={locatePath} />

        <h1 className="privacy__h1">プライバシーポリシー</h1>

        <section className="privacy__section">
          <h2 className="privacy__h2">Google AdSense</h2>
          <p>
            <a href="https://kuroro.blog/">KURORO BLOG</a> (以下、当サイト)では、第三者配信による広告サービス(Google
            AdSense)を利用しています。
          </p>
          <p>
            第三者配信事業者は、ユーザーの興味に適したサービスや商品広告を表示するため、「Cookie」を使用し、過去にウェブサイトへアクセスした際の情報を基に広告配信しています。
          </p>
          <p>
            Cookieを無効にして個人情報を保護する、並びにGoogle
            AdSenseに関する詳細を確認する場合は、以下のページをご覧ください。
          </p>
          <ul>
            <li>
              <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=ja">
                Cookieの設定方法
              </a>
            </li>
            <li>
              <a href="https://support.google.com/adsense/answer/7549925?hl=ja">Google Adsenseについて</a>
            </li>
          </ul>

          <h2 className="privacy__h2">Google analytics</h2>
          <p>当サイトではアクセス解析のためにGoogle Analyticsを利用しています。</p>
          <p>Google Analyticsでは「Cookie」を使用し、個人を特定する情報を含まずにデータを収集します。</p>
          <p>
            Google
            Analyticsの利用により収集されたデータは、Google社で用意するプライバシーポリシーに基づいて管理されます。
          </p>

          <ul>
            <li>
              <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=ja">
                Cookieの設定方法
              </a>
            </li>
            <li>
              <a href="https://policies.google.com/privacy?_ga=2.256249995.1210012706.1620623821-2016195288.1612850625">
                Google社で用意するプライバシーポリシー
              </a>
            </li>
          </ul>

          <h2 className="privacy__h2">MICROSOFT CLARITY</h2>
          <p>当サイトではアクセス解析のためにMICROSOFT CLARITYを利用しています。</p>
          <p>MICROSOFT CLARITYでは「Cookie」を使用し、個人を特定する情報を含まずにデータを収集します。</p>
          <p>
            MICROSOFT CLARITYの利用により収集されたデータは、Microsoft社で用意するMicrosoft Privacy
            Statementに基づいて管理されます。
          </p>

          <ul>
            <li>
              <a href="https://privacy.microsoft.com/en-us/privacystatement">
                Microsoft社で用意するMicrosoft Privacy Statement
              </a>
            </li>
          </ul>

          <h2 className="privacy__h2">個人情報の利用</h2>
          <p>
            当サイトでは、メール、フォームからのお問い合わせや、コメント欄への投稿時に、名前(ハンドルネーム)や、メールアドレスなどの個人情報をご登録頂く場合がございます。
          </p>
          <p>
            これらの個人情報は、不正防止や、質問に対する回答および必要な情報を電子メールなどでご連絡する場合に利用するものであり、これ以外の目的では利用致しません。
          </p>
          <p>当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。</p>
          <ul>
            <li>ご本人からの了承がある場合</li>
            <li>法令等への協力のため、開示が必要となる場合</li>
          </ul>
          <p>
            ご本人からの個人データの開示、訂正、削除をご希望の場合には、ご本人であることをご確認した上で、迅速に対応致します。
          </p>

          <ul>
            <li>
              <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=ja">
                Cookieの設定方法
              </a>
            </li>
            <li>
              <a href="https://policies.google.com/privacy?_ga=2.256249995.1210012706.1620623821-2016195288.1612850625">
                Google社で用意するプライバシーポリシー
              </a>
            </li>
          </ul>
        </section>

        <Breadcrumb
          breadcrumb={[
            {
              link: '/',
              text: 'KURORO BLOG',
            },
            {
              link: '/privacy/',
              text: 'プライバシーポリシー',
            },
          ]}
        />

        <Footer />
      </div>
    </div>
  )
}
